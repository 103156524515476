import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { MedWrapper, buttonTwo, fontSizer } from "../../../Utilities"

const NavigationStyled = styled.div`
  margin-bottom: 5rem;

  .wrapper {
    ${MedWrapper};
  }

  nav {
    width: 100%;
    margin: 0 auto;
    text-align: center;

    a {
      ${buttonTwo};
      ${fontSizer(1.6, 1.8, 76.8, 110, 1.8)};
      display: block;
      max-width: 25rem;
      margin: 2rem 0 0 0;
      text-align: center;

      @media (min-width: 768px) {
        display: inline-block;
        max-width: 100%;
        margin: 0 2rem;
      }
    }
  }
`

const Navigation = ({ next, prev }) => {
  return (
    <NavigationStyled>
      <div className="wrapper">
        <nav>
          {prev && (
            <Link to={`/portfolio/${prev}`}>
              Next Portfolio
            </Link>
          )}
          <Link to="/services">
            Portfolio Page
          </Link>
          {next && (
            <Link to={`/portfolio/${next}`}>
              Prev Portfolio
            </Link>
          )}
        </nav>
      </div>
    </NavigationStyled>
  )
}

export default Navigation
