import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

const LongImageStyled = styled.div`
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
`

const LongImage = ({ fluid, title }) => {
  return (
    <LongImageStyled>
      <Img fluid={fluid} alt={title} />
    </LongImageStyled>
  )
}

export default LongImage
