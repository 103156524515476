import React from "react"
import styled from "styled-components"

import MainContent from "./MainContent"
import ProjectDetails from "./ProjectDetails"

import { MedWrapper } from "../../../Utilities"

const MainLayoutStyled = styled.article`
  width: 100%;

  .article-wrapper {
    ${MedWrapper};
  }

  .article-meta {
    width: 100%;

    @media (min-width: 1025px) {
      width: calc(33.33%);
    }
  }

  .article-content {
    width: 100%;

    @media (min-width: 1025px) {
      width: calc(66.66% - 3rem);
      margin-left: 3rem;
    }
  }
`

const MainLayout = props => {
  const { port, workCat } = props
  return (
    <MainLayoutStyled>
      <div className="article-wrapper">
        <div className="article-meta">
          <ProjectDetails
            workCat={workCat}
            workType={port.acf.type_work}
            websiteUrl={{
              req: port.acf._swb_web_link_req,
              url: port.acf._swb_website_link_url,
            }}
          />
        </div>
        <div className="article-content">
          <MainContent title={port.title} content={port.acf.write_up} />
        </div>
      </div>
    </MainLayoutStyled>
  )
}

export default MainLayout
