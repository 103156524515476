import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

const MultiImagesStyled = styled.div`
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
`

const MultiImages = ({ images }) => {
  return (
    <MultiImagesStyled>
      {images.map((image, index) => {
        return (
          <div key={index}>
            <Img
              fluid={image.portfolio_image.localFile.childImageSharp.fluid}
              alt={image.portfolio_image.alt_text}
            />
          </div>
        )
      })}
    </MultiImagesStyled>
  )
}

export default MultiImages
