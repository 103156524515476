import React from "react"
import styled from "styled-components"

import { bodyCopy, colors, buttonTwo, fontSizer } from "../../../Utilities"

const ProjectDetailsStyled = styled.div`
  .port-work-type {
    position: relative;
    margin-right: 2.5rem;

    ul {
      li {
        ${bodyCopy};
        color: #585858;
        font-weight: 300;
        text-align: center;

        @media (min-width: 1025px) {
          text-align: right;
        }
      }
    }

    &::after {
      display: block;
      position: absolute;
      width: 100%;
      height: 0.8rem;
      left: 0.5rem;
      bottom: -2rem;
      right: 0.5rem;
      content: "";
      background: ${colors.colorTertiary};
    }

    @media (min-width: 1025px) {
      &::after {
        display: block;
        position: absolute;
        width: 0.8rem;
        height: auto;
        top: 0.5rem;
        bottom: 0.5rem;
        right: -3rem;
        left: auto;
        content: "";
        background: ${colors.colorTertiary};
      }
    }
  }

  &.port-details__web {
    .port-work-type {
      &::after {
        background: ${colors.colorPrimary};
      }
    }
  }

  &.port-details__mark {
    .port-work-type {
      &::after {
        background: ${colors.colorAccent};
      }
    }
  }

  &.port-details__brand {
    .port-work-type {
      &::after {
        background: ${colors.colorSecondary};
      }
    }
  }

  &.port-details__default {
    .port-work-type {
      &::after {
        background: ${colors.colorTertiary};
      }
    }
  }

  .port-website-link {
    margin-top: 4rem;
    margin-right: 0;
    margin-left: auto;
    text-align: center;

    @media (min-width: 1025px) {
      margin-top: 7.5rem;
      max-width: 25rem;
      text-align: right;
    }

    p {
      ${bodyCopy};
      margin-bottom: 5rem;
      color: #585858;
      font-weight: 300;
    }

    a {
      ${buttonTwo};
      ${fontSizer(1.8, 2, 76.8, 110, 2)};
    }
  }
`

const ProjectDetails = ({ workCat, workType, websiteUrl }) => {
  const portWorkCat =
    workCat[0] === 187
      ? "web"
      : workCat[0] === 188
      ? "mark"
      : workCat[0] === 189
      ? "brand"
      : "default"

  return (
    <ProjectDetailsStyled
      className={`port-details port-details__${portWorkCat}`}
    >
      <div className="port-work-type">
        <ul>
          {workType.map((type, index) => {
            return <li key={index}>{type}</li>
          })}
        </ul>
      </div>
      {websiteUrl.req === "yes" && (
        <div className="port-website-link">
          <p>Learn more about the full project here.</p>
          <a target="_blank" rel="noopener noreferrer" href={websiteUrl.url}>
            View Website
          </a>
        </div>
      )}
    </ProjectDetailsStyled>
  )
}

export default ProjectDetails
