import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/Seo/seo"
import Hero from "../components/PageParts/SinglePost/Hero"
import MainLayout from "../components/PageParts/SinglePortfolio/MainLayout"
import LongImage from "../components/PageParts/SinglePortfolio/LongImage"
import MultiImages from "../components/PageParts/SinglePortfolio/MultiImages"
import Navigation from "../components/PageParts/SinglePortfolio/Navigation"

import { MedWrapper } from "../Utilities"

const PortfolioStyled = styled.section`
  .port-wrapper {
    ${MedWrapper};
    padding: 0;

    @media (min-width: 768px) {
      padding: 2rem;
    }
  }
`

const Portfolio = props => {
  const { portfolio } = props.data
  const selectPortImg = portfolio.acf._swb_portfolio_images_selection
  const displayLong = selectPortImg === "long" ? true : false
  const displayMulti = selectPortImg !== "long" ? true : false

  const metaImg = portfolio.acf.main_image.localFile.publicURL
  const seoTitle = portfolio.acf.seo_title
    ? portfolio.acf.seo_title
    : portfolio.title
  const seoDescription = portfolio.acf.seo_description
    ? portfolio.acf.seo_description
    : portfolio.title

  return (
    <Layout location={props.location.pathname}>
      <SEO
        title={seoTitle}
        description={seoDescription}
        location={props.location.pathname}
        pageImg={metaImg}
      />
      <Hero img={portfolio.acf.main_image.localFile.childImageSharp.fluid} />
      <PortfolioStyled>
        <div className="port-wrapper">
          <MainLayout port={portfolio} workCat={portfolio.work_type} />
          {displayMulti && (
            <MultiImages images={portfolio.acf.portfolio_images} />
          )}
          {displayLong && (
            <LongImage
              fluid={
                portfolio.acf._swb_long_portfolio_image.localFile
                  .childImageSharp.fluid
              }
              title={portfolio.title}
            />
          )}
        </div>
      </PortfolioStyled>
      <Navigation next={props.pageContext.next} prev={props.pageContext.prev} />
    </Layout>
  )
}

export const query = graphql`
  query portfolioQuery($slug: String!) {
    portfolio: wordpressWpPortfolio(slug: { eq: $slug }) {
      work_type
      date(formatString: "MMMM D, Y")
      content
      title
      wordpress_id
      acf {
        main_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(quality: 100, maxWidth: 2500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        tag_line
        write_up
        type_work
        seo_title
        seo_description
        _swb_web_link_req
        _swb_website_link_url
        _swb_portfolio_images_selection
        _swb_long_portfolio_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1750) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        portfolio_images {
          portfolio_image {
            alt_text
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1750) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
export default Portfolio
